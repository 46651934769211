import Section from 'components/Section'
import { useContext } from 'react'
import SectionContainer from 'components/Section/SectionContainer'
import { useInlineMemoizedContentGetter, useMemoizedContentGetter } from 'utils/contentful'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import { Grid, Box } from '@achieve/ascend'
import { Eyebrow } from 'components/Eyebrow'
import { IconListItem } from 'components/IconListItem'
import { BLOCKS } from '@contentful/rich-text-types'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'
import SlideOnVisible from 'components/SlideOnVisible/SlideOnVisible'
import { StickyTabs } from 'components/StickyTabs'
import {
  SLIDE_ON_VISIBLE_EASING_DEFAULT,
  SLIDE_ON_VISIBLE_DURATION_DEFAULT,
  SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT,
} from 'constants/animation'
import classNames from 'classnames'
import { AchieveLink } from 'components/AchieveLink'
import { GraphicHandler } from 'components/Graphic/GraphicHandler'
import { LayoutContext } from 'providers/LayoutProvider'
import AppStoreButtons from 'components/AppStoreButtons/AppStoreButtons'
import { PhoneDataContext } from 'providers/PhoneDataProvider'
import { mainProductConfig } from 'services/contentful/helpers'
import styles from './ProductDetailsTabs.module.scss'

function ProductDetailsTabsModule({
  title,
  eyebrow,
  grid,
  event,
  additionalConfiguration = {},
  helpTextMobile,
  primaryCta,
}) {
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  const phoneData = useContext(PhoneDataContext)
  const {
    jsonContent: { stickyTabsLabels = [], inverse = false, primaryColor, secondaryColor } = {},
  } = useInlineMemoizedContentGetter(additionalConfiguration, ['jsonContent'])
  const makeTabObject = (item, index) => {
    return {
      tabLabel:
        stickyTabsLabels[index] ?? item?.cardTitleText?.json?.content?.[0]?.content?.[0].value,
      tabItem: makeSection(item, index),
    }
  }

  const makeSection = (item, index) => {
    const {
      eyebrow,
      cardTitleText,
      cardSubtitleText,
      cardText,
      iconCardItemCollection,
      cardLink,
      secondaryCardLink,
      cardImage,
      cardImageResponsive,
      cardImageAlternative,
      cardBadgeImage,
      cardJson,
      linkAndroid,
      imageAndroid,
      linkApple,
      imageApple,
      graphicComponent,
      extraComponentsCollection,
    } = item || {}
    const badgeProps = {
      anchor: ANCHOR_POINTS.RIGHT_TOP,
      translateX: -90,
      translateY: 25,
    }
    const highlightBox = extraComponentsCollection?.items?.filter(
      (e) => e?.fieldName == 'highlightBox'
    )?.[0]
    const callCta = extraComponentsCollection?.items?.filter((e) => e?.fieldName == 'callCta')?.[0]
    const LinkTextCallCta = callCta
      ? `${callCta?.linkText?.json?.content?.[0]?.content?.[0]?.value.replaceAll(
          '[ACX_PHONE_NUMBER]',
          phoneData.phoneNumber
        )}`
      : ''

    const appStoreButtons = (linkApple || imageApple || linkAndroid || imageAndroid) && (
      <AppStoreButtons
        appleButton={linkApple}
        appleImage={imageApple}
        googleButton={linkAndroid}
        googleImage={imageAndroid}
        navLinkSection="Product Details"
      />
    )

    const oddItem = index % 2 == 0

    return (
      <Grid
        container
        key={`product-card-${index}`}
        className={classNames(
          inverse
            ? oddItem
              ? 'group secondary items-center justify-center px-4 py-0 bg-white data-[inverse="false"]:bg-[#f5f9ff] data-[inverse="true"]:bg-white data-[variation="variation1"]:py-8 data-[variation="variation1"]:px-4 lg:[&_.pd-item-card-image]:left-[-170px] group-data-[variation="variation1"]:[&_.pd-item-card-image]:mt-[-72px] group-data-[variation="variation1"]:[&_.pd-item-card-image]:w-full group-data-[variation="variation1"]:[&_.pd-item-card-image]:right-[-165px] lg:group-data-[variation="variation1"]:[&_.pd-item-card-image]:left-[152px] lg:pt-16 lg:pb-0 lg:px-0 lg:min-h-[400px] lg:[&_pd-item-panel-left]:[&_pd-item-brand]:pl-[50px] lg:[&_pd-item-panel-left]:[&_pd-item-brand]:pr-[0px] xl:[&_pd-item-panel-left]:[&_pd-item-brand]:px-0'
              : 'group items-center justify-center px-4 py-0 bg-white data-[variation="variation1"]:py-8 data-[variation="variation1"]:px-4 lg:pt-16 lg:pb-0 lg:px-0 lg:min-h-[400px] lg:justify-[inherit] data-[inverse="true"]:bg-[#f5f9ff]'
            : oddItem
            ? styles['pd-item']
            : styles['pd-item-secondary'],
          styles[`backgroud-${cardJson?.backgroud}`] ?? '',
          (!cardImageAlternative && styles['pd-item-no-secondary-image']) ?? '',
          `flex flex-row ${
            inverse
              ? oddItem
                ? 'flex-row-reverse'
                : 'flex-row'
              : oddItem
              ? 'flex-row'
              : 'flex-row-reverse'
          }`
        )}
        data-testid="pd-section"
        data-variation={cardJson?.cardVariation || 'default'}
        data-inverse={inverse}
        style={{
          ...(primaryColor &&
            secondaryColor && {
              background: inverse
                ? !oddItem
                  ? primaryColor
                  : secondaryColor
                : !oddItem
                ? secondaryColor
                : primaryColor,
            }),
        }}
      >
        <Grid
          item
          xxs={12}
          lg={6}
          className={classNames(
            'pd-item-panel-left pt-12 px-0 pb-0 max-w-[500px] group-data-[variation="variation1"]:py-4 group-data-[variation="variation1"]:px-0 [.pd-item-no-secondary-image_&]:py-8 [.pd-item-no-secondary-image_&]:px-0 lg:p-0 lg:max-w-[initial]',
            'lg:[&_pd-item-brand]:pr-[50px] xl:[&_pd-item-brand]:px-0'
          )}
        >
          <Box className={'pd-item-brand flex flex-col max-w-[420px] my-0 mx-auto relative gap-0'}>
            <SlideOnVisible
              duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
              timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
              easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
            >
              <div>
                <MediaImageStandard
                  content={cardImageResponsive}
                  className={classNames('rounded-[8px] h-full w-full', 'inline lg:hidden')}
                />
                <MediaImageStandard
                  content={cardImage}
                  className={classNames('rounded-[8px] h-full w-full', 'hidden lg:inline')}
                />
                {cardJson?.disclosure && (
                  <AchieveLink
                    href="/policies#Disclosures"
                    className={classNames(
                      'text-[#42546b] no-underline hover:underline hover:text-[#42546b]',
                      'hidden lg:block'
                    )}
                    data-testid="product-detail-disclosure"
                    track={{
                      ...{
                        list_name: 'PRODUCT DETAIL DISCLOSURE',
                        click_url: '/policies#Disclosures',
                        click_id: 'disclosures',
                        click_text: 'disclosures',
                        click_type: 'Link Click',
                        event_action: 'link_click',
                        nav_link_section: 'Product Details',
                        track_event: 'link_click',
                      },
                      ...event,
                    }}
                  >
                    <Typography
                      className={
                        cardJson?.disclosure === 'mobile'
                          ? 'mb-[52px]'
                          : cardJson?.disclosure === 'left'
                          ? 'mt-2 text-left'
                          : 'mt-2 text-right'
                      }
                      content={'Disclosures'}
                      variant={'bodyXs'}
                    />
                  </AchieveLink>
                )}
              </div>
            </SlideOnVisible>
            {cardImageAlternative && (
              <Box
                className={
                  cardJson?.disclosure
                    ? 'pd-item-card-image-disclosure relative mt-[-70px] lg:mt-[-224px] lg:w-[130%] lg:right-[-70px] [.secondary_&]:left-[-170px] data-[variation="variation1"]:[.secondary_&]:left-[-152px]'
                    : 'pd-item-card-image relative mt-[-70px] group-data-[variation="variation1"]:py-0 group-data-[variation="variation1"]:px-[10px] group-data-[variation="variation1"]:mt-[-35px] lg:mt-[-200px] lg:w-[130%] lg:right-[-70px] lg:group-data-[variation="variation1"]:mt-[-72px] lg:group-data-[variation="variation1"]:w-full lg:group-data-[variation="variation1"]:right-[-165px]'
                }
              >
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={350}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div className={cardJson?.disclosure ? 'mb-[52px]' : ''}>
                    {cardBadgeImage && (
                      <AchieveBadge
                        className="block lg:hidden w-[70px] h-[70px] lg:w-[80px] lg:h-[80px]"
                        content={cardBadgeImage}
                        {...{ ...badgeProps, ...(cardJson?.achieveBadge || {}) }}
                      />
                    )}
                    {cardBadgeImage && (
                      <AchieveBadge
                        className="hidden lg:block w-[70px] h-[70px] lg:w-[80px] lg:h-[80px]"
                        content={cardBadgeImage}
                        {...{ ...badgeProps, ...(cardJson?.achieveBadgeLg || {}) }}
                      />
                    )}
                    {graphicComponent ? (
                      <Box className={'group'} data-component={graphicComponent?.component?.[0]}>
                        <GraphicHandler
                          classNameContainer={
                            'group-data-[component="GraphicCardList"]:min-w-[250px] group-data-[component="GraphicCardList"]:m-[30px] group-data-[component="GraphicCommentBubble"]:min-w-[250px] lg:group-data-[component="GraphicCardList"]:min-w-[350px] lg:group-data-[component="GraphicCardList"]:w-[350px] group-data-[component="GraphicCardList"]:lg:m-[55px] lg:[component="GraphicCommentBubble"]:min-w-[300px] lg:[component="GraphicCommentBubble"]:w-[300px] xl:[component="GraphicCommentBubble"]:min-w-[350px] xl:[component="GraphicCommentBubble"]:w-[350px]'
                          }
                          imageComponent={graphicComponent?.component?.[0]}
                          jsonContent={graphicComponent}
                        />
                      </Box>
                    ) : (
                      <MediaImageStandard
                        content={cardImageAlternative}
                        className={
                          'object-contain object-[center_top] max-h-[inherit_!important] min-h-[inherit_!important] h-auto '
                        }
                        objectPosition="center-top"
                        objectFit="contain"
                      />
                    )}
                    {cardJson?.disclosure && (
                      <AchieveLink
                        href="/policies#Disclosures"
                        className={
                          'text-[#42546b] no-underline hover:underline hover:text-[#42546b] block lg:hidden'
                        }
                        data-testid="product-detail-disclosure"
                        track={{
                          ...{
                            list_name: 'PRODUCT DETAIL DISCLOSURE',
                            click_url: '/policies#Disclosures',
                            click_id: 'disclosures',
                            click_text: 'disclosures',
                            click_type: 'Link Click',
                            event_action: 'link_click',
                            nav_link_section: 'Product Details',
                            track_event: 'link_click',
                          },
                          ...event,
                        }}
                      >
                        <Typography
                          content={'Disclosures'}
                          variant={'bodyXs'}
                          style={{ marginTop: '-10px', marginLeft: '32px' }}
                        />
                      </AchieveLink>
                    )}
                  </div>
                </SlideOnVisible>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xxs={12}
          lg={6}
          className={
            'px-0 pt-0 pb-12 max-w-[500px] [data-[variation1]_&]:pt-4 [data-[variation1]_&]:px-0 lg:p-0 lg:max-w-[initial]'
          }
        >
          <SlideOnVisible
            duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
            timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
            easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
          >
            <Box className={'flex flex-col gap-4 max-w-[420px] relative mx-auto my-0'}>
              {eyebrow && (
                <Eyebrow
                  className={'text-left pb-0 hidden lg:block'}
                  content={eyebrow}
                  variant="bodySm"
                  fontWeight="bold"
                  component="h3"
                  black
                  data-testid="pd-item-eyebrow"
                />
              )}
              {cardTitleText && (
                <Typography
                  className={'bodyLg lg:headingMd font-medium'}
                  content={cardTitleText}
                  data-testid="pd-item-title"
                />
              )}
              {cardSubtitleText && (
                <Typography
                  content={cardSubtitleText}
                  variant="bodySm"
                  className={''}
                  fontWeight="regular"
                  data-testid="pd-item-subtitle"
                />
              )}
              {cardText && (
                <Typography
                  content={cardText}
                  variant="bodySm"
                  className={''}
                  fontWeight="regular"
                />
              )}
              {iconCardItemCollection?.items?.length > 0 && (
                <ul className={'m-0 p-0'}>
                  {iconCardItemCollection?.items.map((icon, index) => {
                    return (
                      <IconListItem
                        key={`icon-list-item-${index}`}
                        iconColor={icon?.iconColor}
                        iconName={icon?.iconName}
                        itemText={icon?.description}
                        classNameText={'leading-[0]'}
                        variant="bodySm"
                        iconSize={20}
                      />
                    )
                  })}
                </ul>
              )}

              {highlightBox && (
                <Box
                  className={
                    'flex flex-row gap-2 items-center text-[#1d252f] p-2 rounded-md bg-[#d2e2fe]'
                  }
                >
                  <MediaImageStandard
                    className={''}
                    layout="fixed"
                    width={20}
                    height={30}
                    content={highlightBox?.cardImage}
                  />
                  <Typography
                    content={highlightBox?.cardText}
                    fontWeight="bold"
                    className={'bodyXs lg:bodyXs'}
                  />
                </Box>
              )}

              {appStoreButtons && (
                <div className={'flex justify-center lg:flex lg:justify-start'}>
                  {appStoreButtons}
                </div>
              )}

              {secondaryCardLink && (
                <LinkButton
                  track={{
                    ...{
                      list_name: 'PRODUCT DETAILS',
                      click_id: secondaryCardLink?.linkText?.json?.content[0]?.content[0]?.value,
                      click_text: secondaryCardLink?.linkText?.json?.content[0]?.content[0]?.value,
                      click_url: secondaryCardLink?.linkHref,
                      nav_link_section: 'Product Details',
                      track_event: 'internal_campaign_click',
                      event_action: 'button_click',
                      event_type: 'button_click',
                    },
                    ...secondaryCardLink?.fields?.event,
                  }}
                  content={secondaryCardLink}
                  typographicOptions={{
                    variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                    fontWeight: 'bold',
                  }}
                  color="primary"
                  className={'self-center w-full lg:w-fit lg:self-start'}
                  variant="contained"
                  data-testid="pd-item-secondary-cta"
                />
              )}

              {cardLink && (
                <LinkButton
                  track={{
                    ...{
                      list_name: 'PRODUCT DETAILS LINK',
                      click_id: cardLink?.linkText?.json?.content[0]?.content[0]?.value,
                      click_text: cardLink?.linkText?.json?.content[0]?.content[0]?.value,
                      click_type: 'Link Click',
                      click_url: cardLink?.linkHref,
                      nav_link_section: 'Product Details',
                      track_event: 'link_click',
                      event_action: 'link_click',
                      event_type: 'link_click',
                    },
                    ...cardLink?.fields?.event,
                  }}
                  content={cardLink}
                  typographicOptions={{
                    variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                    fontWeight: 'bold',
                  }}
                  color="primary"
                  className={
                    'p-0 [data-[variation="variation1"]_&]:pl-0 [data-[variation="variation1"]_&]:[&_p]:w-full [data-[variation="variation1"]_&]:[&_p]:text-left self-center w-full lg:w-fit lg:self-start'
                  }
                  variant="text"
                  data-testid="pd-item-primary-cta"
                />
              )}

              {callCta && phoneData.phoneNumber && (
                <AchieveLink
                  href={`tel:${phoneData.phoneNumber}`}
                  noLink
                  noPreserveParams
                  track={{
                    list_name: 'Product Details Phone',
                    click_id: LinkTextCallCta,
                    click_type: 'Phone Click',
                    click_text: `${
                      mainProductConfig[siteEnv || 'achieve']?.['MainProduct']
                    } | ${LinkTextCallCta}`,
                    nav_link_section: 'Product Details',
                    track_event: 'link_click',
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    className="bodySm lg:bodyLg"
                    content={LinkTextCallCta}
                  />
                </AchieveLink>
              )}

              {cardJson?.disclaimer && (
                <Typography content={cardJson?.disclaimer} variant={'bodyXs'} />
              )}
            </Box>
          </SlideOnVisible>
        </Grid>
      </Grid>
    )
  }

  return (
    <Section
      className={classNames('pt-12 pb-0 px-0 lg:pt-16')}
      contain={false}
      style={{ ...(primaryColor && { backgroundColor: primaryColor }) }}
      data-testid="ProductDetailsTabs_section"
    >
      <Typography
        className={'tracking-[0.25em] pb-2 lg:pb-4'}
        textAlign="center"
        variant="bodySm"
        fontWeight="bold"
        content={eyebrow?.textContent}
        data-testid="pd-eyebrow"
      />
      <Typography
        className={'headingMd lg:displayLg font-medium'}
        textAlign="center"
        content={title?.textContent}
        data-testid="pd-title"
      />
      {grid?.gridItemsCollection?.items && (
        <Box>
          <div className="hidden lg:block">
            {grid?.gridItemsCollection?.items?.map((item, index) => makeSection(item, index))}
          </div>
          <StickyTabs
            className={'mt-8 block lg:hidden'}
            isMobile={true}
            stickyTabsContent={grid?.gridItemsCollection?.items?.map((item, index) =>
              makeTabObject(item, index)
            )}
            event={event}
          />
        </Box>
      )}
      {primaryCta && (
        <SectionContainer className="block lg:hidden">
          <LinkButton
            track={{
              ...{
                list_name: 'PRODUCT DETAILS',
                click_id: primaryCta?.linkText?.json?.content[0]?.content[0]?.value,
                click_text: primaryCta?.linkText?.json?.content[0]?.content[0]?.value,
                click_url: primaryCta?.linkHref,
                nav_link_section: 'Product Details',
                track_event: 'internal_campaign_click',
                event_action: 'button_click',
                event_type: 'button_click',
              },
              ...primaryCta.event,
            }}
            content={primaryCta}
            typographicOptions={{
              variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
              fontWeight: 'bold',
            }}
            color="primary"
            className={'self-center w-full lg:w-fit lg:self-start'}
            variant="contained"
            data-testid="pd-item-secondary-cta"
          />
        </SectionContainer>
      )}
      {helpTextMobile && (
        <SectionContainer className="block lg:hidden">
          <Typography
            className={'text-[#42546b] mt-4'}
            fontWeight="regular"
            variant="bodyXs"
            content={helpTextMobile?.textContent?.json}
            data-testid="pd-help-text"
          />
        </SectionContainer>
      )}
    </Section>
  )
}

function ProductDetailsTabs({ content }) {
  const { title, eyebrow, grid, helpTextMobile, primaryCta, event } = useMemoizedContentGetter(
    content,
    ['title', 'eyebrow', 'grid', 'helpTextMobile', 'primaryCta', 'event']
  )

  return (
    <ProductDetailsTabsModule
      title={title}
      eyebrow={eyebrow}
      grid={grid}
      event={event}
      additionalConfiguration={content?.fields?.additionalConfiguration}
      helpTextMobile={helpTextMobile}
      primaryCta={primaryCta}
    />
  )
}
export { ProductDetailsTabs, ProductDetailsTabsModule }
export default ProductDetailsTabs
